<template>
  <div class="mt-2 activity-list-wrapper">
    <div>
      <div v-if="isLoading" class="activity-list">
        <OtherLoading class="ticket-loading" />
      </div>
      <div v-else-if="items.length" class="activity-list">
        <div class="activity-card-wrapper">
          <div
            v-for="item in items"
            :key="item.id"
            class="activity-card"
            :class="[
              { private: item.ticket_activity_type_id == 1 },
              { system: item.ticket_log_type_id == 1 },
            ]"
          >
            <div class="name">
              <div>
                {{ item.name_created || "-" }}
                <span
                  v-if="
                    item.ticket_activity_type_id == 1 &&
                    item.ticket_log_type_id == 2
                  "
                  class="sub-title"
                  >added a private note</span
                >
              </div>

              <font-awesome-icon
                v-if="item.ticket_log_type_id == 2 && !item.isEdit"
                icon="edit"
                class="icon"
                @click="handleEditNote(item)"
              />
            </div>
            <div class="date">
              {{ $moment(item.created_time).fromNow() }}
              ({{ $moment(item.created_time).format("DD/MM/YYYY hh:mm:ss") }})
            </div>

            <div
              class="note mt-2"
              v-if="!item.isEdit"
              v-html="replaceCharacter(item.note)"
            ></div>
            <div class="activity-form" v-else>
              <TextEditorsTiny
                textFloat=""
                :rows="4"
                :value="selectedFormItem.note"
                name="ticketNote"
                placeholder="Type something..."
                class="activity-input h-300px"
                :v="$v.selectedFormItem.note"
                @onDataChange="(val) => (selectedFormItem.note = val)"
              />
              <b-form-checkbox
                v-model="selectedFormItem.ticket_activity_type_id"
                :checked="1"
                :unchecked-value="0"
                @change="selectedFormItem.ticket_activity_type_id = $event"
                class="mb-1"
                >Private Note</b-form-checkbox
              >

              <div class="activity-footer">
                <b-button
                  class="btn-cancel btn-width"
                  @click="item.isEdit = false"
                  >Cancel</b-button
                >
                <b-button
                  class="main-color btn-save"
                  @click="sendForm('selectedFormItem')"
                >
                  Save
                </b-button>
              </div>
            </div>
          </div>

          <Pagination
            :pageOptions="pageOptions"
            :filter="filter"
            :rows="rows"
            @pagination="pagination"
            @handleChangeTake="handleChangeTake"
            class="mt-2"
          />
        </div>
      </div>
    </div>

    <div class="activity-form">
      <TextEditorsTiny
        textFloat="Add Note"
        :rows="4"
        :value="selectedForm.note"
        name="ticketNote"
        placeholder="Type something..."
        class="activity-input"
        :v="$v.selectedForm.note"
        @onDataChange="(val) => (selectedForm.note = val)"
      />

      <b-form-checkbox
        v-model="selectedForm.ticket_activity_type_id"
        :checked="1"
        :unchecked-value="0"
        @change="selectedForm.ticket_activity_type_id = $event"
        class="mb-1"
        >Private Note</b-form-checkbox
      >

      <div class="activity-footer">
        <b-button class="btn-cancel btn-width" @click="handleCancel"
          >Cancel</b-button
        >
        <b-button class="main-color btn-save" @click="sendForm('selectedForm')">
          Save
        </b-button>
      </div>
    </div>

    <ModalConfirm
      ref="confirmModal"
      text="คุณต้องการออกจากหน้านี้หรือไม่"
      @handler="onConfirm"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import OtherLoading from "@/components/loading/OtherLoading";
import TextEditorsTiny from "@/components/inputs/TextEditorsTiny";
import { required } from "vuelidate/lib/validators";
import ModalConfirm from "@/components/modal/ModalConfirm";
export default {
  components: {
    Pagination,
    OtherLoading,
    TextEditorsTiny,
    ModalConfirm,
  },
  props: {
    ticketId: {
      required: true,
      type: String,
    },
    isShowActivity: {
      required: true,
      type: Boolean,
    },
    ticketDetail: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      items: [],
      isLoading: false,
      filter: {
        ticket_id: 0,
        is_show_all_activity: this.isShowActivity ? 1 : 0,
        page: 0,
        take: 5,
      },
      rows: 0,
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      selectedForm: {
        id: 0,
        ticket_id: 0,
        ticket_activity_type_id: 0,
        note: "",
        ref_id: 0,
        deleted: 0,
      },
      selectedFormItem: {
        id: 0,
        ticket_id: 0,
        ticket_activity_type_id: 0,
        note: "",
        ref_id: 0,
        deleted: 0,
      },
    };
  },
  validations() {
    return {
      selectedForm: {
        note: { required },
      },
      selectedFormItem: {
        note: { required },
      },
    };
  },
  watch: {
    ticketId: {
      handler(newVal) {
        if (newVal) this.getList();
      },
      immediate: true,
    },
    isShowActivity: {
      handler() {
        this.filter.page = 0;
        this.getList();
      },
    },
  },
  methods: {
    replaceCharacter(str) {
      return str.replace(/(?:\r\n|\r|\n)/g, "<br>");
    },
    handleCancel() {
      if (this.selectedForm.note.length) {
        this.$refs.confirmModal.show();
      } else {
        this.onConfirm(true);
      }
    },
    onConfirm(val) {
      if (val) this.$router.push("/ticket-activity");
    },
    onCancel(form) {
      const defaultVal = {
        id: 0,
        ticket_id: 0,
        ticket_activity_type_id: 0,
        note: "",
        ref_id: 0,
        deleted: 0,
      };
      this[form] = {
        ...defaultVal,
      };
    },
    truncate(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
    handleEditNote(item) {
      this.items = this.items.map((el) => {
        return { ...el, isEdit: el.id === item.id ? !el.isEdit : false };
      });

      const target = this.items.find((el) => el.id === item.id);

      if (target.isEdit) {
        this.selectedFormItem = {
          ...item,
          ticket_activity_type_id: item.ticket_activity_type_id == 1 ? true : 0,
          ticket_id: this.ticketId,
        };
      } else {
        this.selectedFormItem = {
          id: 0,
          ticket_id: 0,
          ticket_activity_type_id: 0,
          note: "",
          ref_id: 0,
          deleted: 0,
        };
      }
    },
    getList: async function () {
      this.isLoading = true;
      const payload = {
        ...this.filter,
        is_show_all_activity: this.isShowActivity ? 1 : 0,
        ticket_id: this.ticketId,
        pageSize: this.filter.take,
      };

      const { data } = await this.axios.post(
        `/ticket/activity/detail`,
        payload
      );
      if (data.result == 1) {
        this.items = data.detail.data;
        this.rows = data.detail.total;
        if (this.filter.page == 0) this.filter.page = data.detail.page;
      }
      this.isLoading = false;
    },
    pagination(value) {
      this.filter.page = value;
      this.getList();
    },
    handleChangeTake(take) {
      this.filter.page = 1;
      this.filter.take = take;
      this.getList();
    },
    async sendForm(form) {
      this.$v[form].$touch();
      if (this.$v[form].$error) {
        return;
      }
      this.$bus.$emit("showLoading");

      const payload = {
        id: this[form].id,
        ticket_id: this.ticketId,
        ticket_activity_type_id:
          this[form].ticket_activity_type_id == 0 ? 2 : 1,
        note: this[form].note,
        ref_id: this[form].ref_id,
        deleted: this[form].deleted,
      };

      const { data } = await this.axios.post(`/ticket/activity`, payload);

      this.$bus.$emit("hideLoading");
      if (data.result == 1) {
        this.isLoading = false;

        this.successAlert().then(() => {
          this.$v[form].$reset();
          this.onCancel(form);
          this.getList();
          this.$emit("getDetail");
        });
      } else {
        this.isLoading = false;
        this.errorAlert(data.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-list-wrapper {
  .activity-list {
    background-color: white;
    padding: 16px;
    border-radius: 0.75rem;
  }

  .activity-card-wrapper {
    display: flex;
    gap: 8px;
    flex-direction: column;
  }

  .activity-card {
    display: flex;
    gap: 2px;
    flex-direction: column;
    padding: 8px;
    border: 1px solid #dadada;
    border-radius: 10px;
    background-color: white;

    &.private {
      background-color: var(--primary-color-light);
      border-color: var(--theme-secondary-color);
    }

    &.system {
      background-color: whitesmoke;
      border-color: #dadada;
    }
  }

  hr {
    width: 100%;
    border: 0.5px solid #eeeeee;
  }

  .title {
    color: #888888;
  }

  .date {
    font-size: 12px;
    color: #bababa;
  }

  .name {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;

    .sub-title {
      font-size: 12px;
      font-weight: normal;
    }

    .icon {
      color: var(--primary-color);
      cursor: pointer;
      font-size: 16px;
    }
  }

  .note {
    ::v-deep p {
      margin: 0;
    }
  }

  .activity-footer {
    display: flex;
    justify-content: flex-end;
    margin: 24px 0;
    gap: 8px;
  }

  .ticket-loading {
    height: 100% !important;
  }
}

.activity-form {
  background-color: white;
  padding: 16px;
  border-radius: 0.75rem;
  margin-top: 8px;
  .activity-input {
    margin-bottom: 8px !important;
  }
}

.activity-card-nodata {
  color: #888888;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  border-radius: 10px;
  opacity: 0.5;
}
</style>
