<template>
  <div :class="[{ 'mt-2 ': !isCustomer }, 'recent-card']">
    <hr v-if="isCustomer" />
    <div class="d-flex justify-content-between align-items-center">
      <div :class="titleClass">
        {{ isCustomer ? "Ticket" : "Recent Ticket" }}
      </div>

      <b-button
        v-if="isCustomer"
        class="btn button btn-mobile mb-2"
        @click="$emit('addActivity')"
      >
        Add New
      </b-button>
    </div>

    <div
      v-for="item in items"
      :key="item.id"
      class="activity-card"
      :class="[{ 'is-close': item.ticket_status_id == 4 }]"
    >
      <div class="activity-icon">
        <font-awesome-icon
          :icon="getActivityIcon(item.activity_source_id)"
          class="icon"
        />
        <hr class="my-2" />
      </div>
      <div class="activity-info">
        <div class="date">
          {{ $moment(item.created_time).format("DD/MM/YYYY hh:mm:ss") }}

          <font-awesome-icon
            icon="edit"
            class="edit-icon"
            @click="
              $router.push({
                path: `/ticket-activity/detail/${item.id}${
                  isCustomer ? '?from=customer' : ''
                }`,
              })
            "
          />
        </div>
        <div class="name">{{ item.name }}</div>
        <ul class="info">
          <li v-if="item.activity_source_type">
            {{ item.activity_source_type }}
          </li>
          <li>Status: {{ item.status }}</li>
          <li v-if="item.sla_status == 1 && item.ticket_status_id === 1">
            <span class="badge badge-new badge-pill">New</span>
          </li>
        </ul>
        <div class="type">{{ item.ticket_type }}</div>
      </div>
    </div>

    <div class="activity-card-nodata" v-if="!items.length">
      <div>ไม่มีกิจกรรมล่าสุด</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    userId: {
      required: true,
      type: String,
    },
    isCustomer: {
      required: false,
      type: Boolean,
      default: false,
    },
    titleClass: {
      required: false,
      type: String,
      default: "title",
    },
    ticketId: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      items: [],
      isBusy: false,
      filter: {
        search: "",
        page: 1,
        take: 5,
      },
    };
  },

  watch: {
    userId: {
      handler(newVal) {
        if (newVal) this.getList();
      },
      immediate: true,
    },
  },
  methods: {
    getActivityIcon(id) {
      if (id == 33) return ["fab", "line"];
      else if (id == 34) return "phone-alt";
      return "phone";
    },
    getList: async function () {
      this.isBusy = true;

      const { data } = await this.axios.get(
        `/ticket/activity/recent/${this.userId}/${this.ticketId}`
      );
      if (data.result == 1) {
        this.items = data.detail;
      }
      this.isBusy = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.recent-card {
  background-color: white;
  padding: 16px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  border-radius: 0.75rem;
  color: var(--main-font-color);

  .activity-card {
    display: flex;
    gap: 8px;

    .activity-icon {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      hr {
        height: 100%;
        border: 0.5px solid #eeeeee;
      }

      .icon {
        color: var(--primary-color);
        cursor: pointer;
        font-size: 24px;
      }
    }

    &.is-close {
      opacity: 0.5;
    }
  }

  .title {
    color: #888888;
  }

  .date {
    font-size: 12px;
    color: #bababa;
    display: flex;
    gap: 8px;
    align-items: center;

    .icon {
      color: var(--primary-color);
    }
  }

  .name {
    font-size: 16px;
  }

  .info {
    font-size: 12px;
    color: #bababa;
    display: flex;
    align-items: center;

    // &ul {
    padding-left: 0;
    margin-bottom: 0;

    li {
      display: inline-flex;
      color: #bababa !important;
      gap: 4px;
      margin-right: 8px;
      align-items: center;
    }

    li::before {
      content: "•";
      padding-right: 8px;
      color: #a2a2a2;
    }
    // }
  }

  .type {
    font-size: 14px;
  }
}

.activity-card-nodata {
  color: #888888;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  border-radius: 10px;
  opacity: 0.5;
}

.edit-icon {
  color: var(--primary-color);
  cursor: pointer;
  font-size: 18px;
}

.badge-new {
  background-color: rgb(39, 189, 167);
  color: white;
  padding: 0.4em 0.6em;
  font-size: 10px;
}
</style>
